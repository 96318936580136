<template>

  <div class="card">
    <div class="card-body tab-content">
      <h6>ADMIN MESSAGES</h6>
      <hr>
      <div class="list-group list-group-flush">
        <router-link 
          v-for="message in messages"
          :key="message.id"
          :to="{ name: 'EditAdminMessage', params: {id: message.id} }"
          type="button"
          class="list-group-item list-group-item-action"
        >
          {{ message.title }}
        </router-link>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      messages: [
        { id: 1234546, title: 'Welcome' },
        { id: 1234547, title: 'Christmas notice' },
        { id: 1234548, title: 'Server status' },
      ],
    }
  },
  computed: {},
  methods: {
    faqsInCategory( cat ) {
      return this.faqs.filter(x => x.category == cat)
    },
  },
};
</script>

<style lang="scss" scoped>
</style>